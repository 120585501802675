/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {observer} from 'mobx-react-lite';
import DisplayGridContent from '../../../common/displayTable/DisplayGridContent';
import DisplayGridLabel from '../../../common/displayTable/DisplayGridLabel';
import DisplayGridItem from '../../../common/displayTable/DisplayGridItem';
import {Check} from '@mui/icons-material';
import getTestID from '../../../../helper/functions/getTestID';
import Constants from '../../../../helper/Constants';
import formatDate from '../../../../helper/functions/formatDate';

interface Props {
    row: any,
}

const ReportsLogsTableRow = observer((props: Props) => {
    const renderGridLabel = (id: string, label: string, xs: number) => (
        <DisplayGridLabel
            id={`${ props.row.id }_${ id }`}
            label={label}
            xs={xs}
        />
    );

    return (
        <DisplayGridContent
            topMargin='0px'
        >
            {renderGridLabel('received_data', formatDate(new Date(props.row.logDate), true),2)}
            {renderGridLabel('title', props.row.title, 3)}
            {renderGridLabel('description', props.row.log, 5)}
            {props.row.type === 'COMMAND' ? (
                <DisplayGridItem xs={2}>
                    <Check
                        {...getTestID(`${ props.row.id }_accepted`)}
                        color="success"
                    />
                </DisplayGridItem>
            ) : renderGridLabel('accepted', Constants.NA, 2)}
        </DisplayGridContent>
    );
});

export default ReportsLogsTableRow;
