/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import AreaChart from '../../../../common/charts/AreaChart';
import { SiteGraphData } from '../../../../../types/components/ApiTypes';
import { cToF } from '../../../../../helper/functions/functions';
import Colors from '../../../../../helper/Colors';
import { Dispatch, SetStateAction } from 'react';

interface Props {
    data: SiteGraphData[],
    id: string,
    legend_show: boolean,
    tempUnit: String,
    timeUnit?: string,
    setStart: Dispatch<SetStateAction<moment.Moment>>
    setEnd: Dispatch<SetStateAction<moment.Moment>>
    setTimeUnit: Dispatch<SetStateAction<string>>,
}

const ViewSiteTempChart = (props: Props) => {
    const series = [
        {
            name: 'Temperature',
            type: 'line',
            data: props.data.map(dataPoint => [
                dataPoint.dt,
                props.tempUnit === 'F' ? cToF(dataPoint.temperature) : dataPoint.temperature,
            ]),
        },
    ];

    const yaxis: ApexYAxis[] = [
        {
            seriesName: `Temperature (${ props.tempUnit })`,
            title: {
                text: `Temperature (${ props.tempUnit })`,
            },
            labels: {
                  minWidth: 80,
                  maxWidth: 80,
            },
        },
    ];
    const gstroke : ApexStroke = {};
    gstroke.width = [ 3 ];
    gstroke.curve = [ 'smooth' ];
    gstroke.dashArray = [ 0 ];

    const popt : ApexPlotOptions = {};

    return (
        <AreaChart
            series={series}
            yaxis={yaxis}
            legendPosition="bottom"
            showLegend={props.legend_show}
            id={props.id}
            type="line"
            showXAxis={true}
            height="100%"
            stroke={gstroke}
            colors={[ Colors.avgTempColor ]}
            plotOptions={popt}
            showMenu={true}
            timeUnit={props.timeUnit}
            setTimeUnit={props.setTimeUnit}
            setStart={props.setStart}
            setEnd={props.setEnd}
            isSiteChart={true}
            enableAnimation
        />
    );
};

export default ViewSiteTempChart;
