import callAPI from '../../callAPI';
import BinsenseStore from '../../../stores/BinsenseStore';

const getAlertHistory = (enqueueSnackbar: any, site_id: number, limit: number, pageIndex: number, type?: string) => {
    callAPI(`alertHistory/${ site_id }/${ type }?limit=${ limit }&page_index=${ pageIndex }`,
        (response) => {
            BinsenseStore.setAlertHistoryData(response.alertHistory.map((row: any, index: number) => ({
                ...row,
                id: index + pageIndex,
            })));
            BinsenseStore.setAlertHistoryCount(response.count);
        },
        enqueueSnackbar
    );
};

export default getAlertHistory;

export const getAllAlertHistory = (enqueueSnackbar: any, site_id: number) => {
    callAPI(`alertHistory/${ site_id }`,
        (response) => {
            BinsenseStore.setAlertHistoryData(response);
        },
        enqueueSnackbar
    );
};
