/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {observer} from 'mobx-react-lite';
import {useEffect, useState} from 'react';
import Constants from '../../../../helper/Constants';
import BinsenseStore from '../../../../stores/BinsenseStore';
import {IconButton, InputAdornment, styled} from '@mui/material';
import {Typography} from '@mui/material';
import {useSnackbar} from 'notistack';
import {AlertHistoryData, Site} from '../../../../types/components/ApiTypes';
import DashboardSkeleton from '../../../common/DashboardSkeleton';
import DisplayGridLabel from '../../../common/displayTable/DisplayGridLabel';
import { getAllAlertHistory } from '../../../../controllers/apiCalls/get/getAlertHistory';
import AlertHistoryTableRow from './AlertHistoryTableRow';
import SelectFieldInput from '../../../common/input/SelectFieldInput';
import TextFieldInput from '../../../common/input/TextFieldInput';
import { Close, Search } from '@mui/icons-material';
import moment from 'moment';
import isKeyInString from '../../../../helper/functions/isKeyInString';
import PaginationTable from '../../../common/displayTable/PaginationTable';
import DisplayGridHeaderRow from '../../../common/displayTable/DisplayGridHeaderRow';

const Root = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
});

const SkeletonRoot = styled('div')({
    width: '100%',
    padding: '20px',
    paddingTop: '0px',
});

const Row = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '20px',
});

interface Props {
    site: Site,
}

const AlertHistoryTable = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();
    const [ type, setType ] = useState('all');
    const [ rows, setRows ] = useState(BinsenseStore.alertHistoryData);
    const [ searchTerm, setSearchTerm ] = useState('');

    useEffect(() => {
        if (searchTerm !== '' || type.toLowerCase() !== Constants.ALL.toLowerCase()) {
            const filteredRows = [] as AlertHistoryData[];
            BinsenseStore.alertHistoryData.forEach(r => {
                const matchType = type.toLowerCase() === Constants.ALL.toLowerCase() ||
                    (type.toLowerCase() !== Constants.ALL.toLowerCase() && r.type.toLowerCase() === type.toLowerCase());

                const matchSearchKey = isKeyInString(r.message, searchTerm) ||
                    isKeyInString(r.alertTime ? moment(new Date(r.alertTime)).format('MMMM Do, YYYY h:mm:ss A')
                    : Constants.NA, searchTerm);

                if (matchType && matchSearchKey) {
                    filteredRows.push(r);
                }
            });

            setRows(filteredRows);
        } else {
            setRows(BinsenseStore.alertHistoryData);
        }
    }, [ type, searchTerm, BinsenseStore.alertHistoryData ]);

    useEffect(() => {
        if (props.site.id > -1) {
            getAllAlertHistory(enqueueSnackbar, props.site.id);
        }
    }, [ props.site ]);

    const handleClick = () => {
        if (searchTerm !== '') {
            setSearchTerm('');
        }
    };

    const renderHeader = (id: string, label: string, xs: number, dropDown?: object) => (
        <DisplayGridLabel
            id={`${ id }_alert_history_header`}
            label={label}
            xs={xs}
            header
            dropDown={dropDown}
        />
    );

    const renderRow = (row: any) => (
        <AlertHistoryTableRow
            row={row}
            key={`${ row.textId }_alert_history_row`}
            refreshAlertHistory={() =>
                getAllAlertHistory(enqueueSnackbar, props.site.id)}
        />
    );

    return (
        <Root>
            <Row>
                <Typography
                    color="primary"
                    width='250px'
                >
                    Alert History
                </Typography>
            </Row>
            {!BinsenseStore.awaitingUserData && !BinsenseStore.awaitingApi ? (
                <div>
                    <Row>
                        <TextFieldInput
                            id='search_term_input_field'
                            label={Constants.SEARCH}
                            field={{
                                value: searchTerm,
                                setValue: setSearchTerm,
                            }}
                            textFieldProps={{
                                InputProps: {
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                id='search_field_icon'
                                                onClick={handleClick}
                                            >
                                                {searchTerm === '' ? <Search/> : <Close/>}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                },
                            }}
                            style={{
                                width: '350px',
                                marginTop: 10,
                            }}
                        />
                    </Row>
                    <PaginationTable
                        rows={rows}
                        renderRow={renderRow}
                    >
                        <DisplayGridHeaderRow>
                            {renderHeader('alertTime', Constants.ALERT_TIME, 3)}
                            {renderHeader('message', Constants.MESSAGE, 4)}
                            {renderHeader('type', Constants.TYPE, 1, (
                                <SelectFieldInput
                                    id="select_type_input"
                                    label={Constants.SELECT_TYPE}
                                    field={{
                                        value: type,
                                        setValue: setType,
                                    }}
                                    options={[ {
                                        title: Constants.ALL,
                                        value: 'all',
                                        id: 'all',
                                    }, {
                                        title: Constants.WEEKLY_RISE,
                                        value: 'roi',
                                        id: 'roi',
                                    }, {
                                        title: Constants.HIGH_THRESHOLD,
                                        value: 'threshold_high',
                                        id: 'threshold_high',
                                    }, {
                                        title: Constants.LOW_THRESHOLD,
                                        value: 'threshold_low',
                                        id: 'threshold_low',
                                    }, {
                                        title: Constants.SYSTEM_THRESHOLD,
                                        value: 'threshold_sys',
                                        id: 'threshold_sys',
                                    }, {
                                        title: Constants.VOLTAGE,
                                        value: 'voltage',
                                        id: 'voltage',
                                    } ]}
                                />
                            ))}
                            {renderHeader('reply', ` ${ Constants.REPLY } (Deprecated)`, 2)}
                            {renderHeader('replyTime', Constants.REPLY_TIME, 2)}
                        </DisplayGridHeaderRow>
                    </PaginationTable>
                </div>
            ) : (
                <SkeletonRoot>
                    <DashboardSkeleton height={600}/>
                </SkeletonRoot>
            )}
        </Root>
    );
});

export default AlertHistoryTable;
