/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import DisplayGridLabel from '../../../common/displayTable/DisplayGridLabel';
import DisplayGridContent from '../../../common/displayTable/DisplayGridContent';
import {observer} from 'mobx-react-lite';
import {CustomerReportData} from '../../../../types/components/ApiTypes';
import getTimeSince from '../../../../helper/functions/getTimeSince';
import DisplayGridItem from '../../../common/displayTable/DisplayGridItem';
import {Warning} from '@mui/icons-material';
import {Typography} from '@mui/material';
import getTestID from '../../../../helper/functions/getTestID';
import PowerSymbol from '../../../common/PowerSymbol';
import Constants from '../../../../helper/Constants';
import DisplayGridChip from '../../../common/displayTable/DisplayGridChip';
import RemoteSerialGridItem from '../../../common/RemoteSerialGridItem';

interface Props {
    row: CustomerReportData,
}

const XS = 1.2;
const fontSize = 12;

const SiteReportTableRowSummary = observer((props: Props) => {
    const device = props.row.devices.length === 1 ? props.row.devices[0] : null;

    const renderGridItem = (id: string, label: string | number , lengthLimit?: number) => (
        <DisplayGridLabel
            id={id}
            label={label}
            xs={XS}
            fontSize={fontSize}
            lengthLimit={lengthLimit}
        />
    );

    const warningLabel = (id: string, label: string | number, showWarning: boolean) => (
        <DisplayGridItem xs={XS}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                {showWarning && (
                    <Warning
                        color="error"
                        style={{ fontSize: 18 }}
                    />
                )}
                <Typography
                    {...getTestID(id)}
                    fontWeight="bold"
                    fontSize={fontSize}
                    paddingLeft="4px"
                >
                    {label}
                </Typography>
            </div>
        </DisplayGridItem>
    );

    const renderVoltageItem = (voltage: number, serial: string, lowBattery: boolean) => voltage !== -1
        ? warningLabel(`${ serial }_voltage`, voltage, lowBattery) : (
            <DisplayGridItem xs={XS}>
                <PowerSymbol
                    acPower
                    batteryLevel={0}
                />
            </DisplayGridItem>
        );

    const showWarning = (date: number, deviceType?: string) => {
        const current = new Date().valueOf();
        if (deviceType === Constants.SOLO) {
            return date ? (current - date) >= (38 * 60 * 60 * 1000) : false;
        } else {
            return date ? current - date >= Constants.MILLISECONDS_IN_A_DAY : false;
        }
    };

    const getTime = (date: number) => getTimeSince(date) === Constants.NA ? '--' : getTimeSince(date);

    return (
        <div style={{ width: '100%' }}>
            <DisplayGridContent>
                {renderGridItem(`${ props.row.structure }_structure_${ device?.serial }`, props.row.structure, 10)}
                {device && device.deviceType === Constants.REMOTE ? (
                    <RemoteSerialGridItem
                        device={device}
                        xs={XS}
                        fontSize={fontSize}
                        testId={`${ props.row.structure }_serial_${ device?.serial }`}
                    />
                ) : renderGridItem(`${ props.row.structure }_serial_${ device?.serial }`, device ? device.serial : '--')}
                {renderGridItem(`${ props.row.structure }_first_associated_${ device?.serial }`,
                    device ? getTime(device.firstAssociated) : '--')}
                {device ? renderVoltageItem(device.voltage, device.serial, device.lowBattery)
                    : renderGridItem(`${ props.row.structure }_voltage`, '--')}
                {renderGridItem(`${ props.row.structure }_last_voltage_call_in_${ device?.serial }`, device
                    ? getTime(device.lastVoltage) : '--')}
                {device ? warningLabel(`${ props.row.structure }_last_cable_call_in_${ device.serial }`,
                    getTime(device.lastCable), showWarning(device.lastCable, device.deviceType))
                    : renderGridItem(`${ props.row.structure }_last_cable_call_in`, '--')}
                {renderGridItem(`${ props.row.structure }_temp_cables_${ device?.serial }`, props.row.tempCables)}
                {renderGridItem(`${ props.row.structure }_moist_cables_${ device?.serial }`, props.row.moistureCables)}
                {renderGridItem(`${ props.row.structure }_device_${ device?.serial }`, device ? device.device : '--')}
                {device ? (
                    <DisplayGridChip
                        id={`${ props.row.structure }_status_${ device.serial }`}
                        status={device.status}
                        xs={1.2}
                        size="small"
                    />
                ) : renderGridItem(`${ props.row.structure }_status`, '--')}
            </DisplayGridContent>
        </div>
    );
});

export default SiteReportTableRowSummary;
