/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {styled} from '@mui/material';
import {observer} from 'mobx-react-lite';
import {useEffect, useMemo, useState} from 'react';
import BinsenseStore from '../../../../stores/BinsenseStore';
import Constants from '../../../../helper/Constants';
import WebStore from '../../../../stores/WebStore';
import ReportsLogsTable from './ReportsLogsTable';
import ReportsTableHeader from '../ReportsTableHeader';
import moment from 'moment';
import getBinLogs from '../../../../controllers/apiCalls/get/getBinLogs';
import {useSnackbar} from 'notistack';
import {BinLog, SelectOptions, Site} from '../../../../types/components/ApiTypes';
import replaceSpaces from '../../../../helper/functions/replaceSpaces';
import DateRangePicker from '../../../common/DateRangePicker';
import SelectFieldInput from '../../../common/input/SelectFieldInput';
import StyledSwitch from '../../../common/input/StyledSwitch';

interface Props {
    site: Site
    noHeaderOptions?: boolean,
    structureId?: number,
    displayOnlyTimePicker?: boolean,
}

const Root = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
});

const Row = styled('div')({
    width: '60%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: '20px',
});

const DateSelectorDiv = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    minWidth: '400px',
    maxWidth: '400px',
});

const ActivityDiv = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    minWidth: '225px',
    maxWidth: '225px',
    marginLeft: '20px',
    marginRight: '20px',
});

const SensorSwitchDiv = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    minWidth: '240px',
});

const ReportsLogsPage = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();
    const [ includeSensorStatus, setIncludeSensorStatus ] = useState(false);

    const getBinOptions = () => {
        const binOptions: any = [];
        let sortIndexAvailable = false;
        const allStructures : any[] = [ ...props.site.bins, ...props.site.grainBags, ...props.site.buildings ];
        allStructures.forEach(obj => {
            if (obj.sortOrder > 0) {
                sortIndexAvailable = true;
            }
        });

        let sortedStructures;
        if (sortIndexAvailable) {
            sortedStructures = allStructures.sort((a, b) => a.sortOrder - b.sortOrder);
        } else {
            sortedStructures = allStructures.sort((a, b) => a.name - b.name);
        }
        sortedStructures.map(b => (
            binOptions.push({
                title: b.name,
                id: `${ replaceSpaces(b.name) }_dropdown`,
                value: String(b.id),
            })
        ));
        return binOptions;
    };

    const binOptions = useMemo(() => getBinOptions(), [ props.site ]);

    const getInitialBin = () => {
        if (props.structureId) {
            return props.structureId.toString();
        }
        return props.noHeaderOptions ? String(WebStore.viewDeviceId) : '';
    };

    const [ start, setStart ] = useState<any>(moment(new Date().getTime() - Constants.MILLISECONDS_IN_A_DAY * 30));
    const [ end, setEnd ] = useState<any>(moment(new Date().getTime()));
    const [ bin, setBin ] = useState(getInitialBin());
    const [ binError, setBinError ] = useState('');

    useEffect(() => {
        if (props.site.id !== -1) {
            getBinLogs(enqueueSnackbar, props.site.id, new Date(start).getTime(), new Date(end).getTime());
        }
    }, [ props.site, start, end ]);

    useEffect(() => {
        if (binOptions.length > 0) {
            setBin(props.structureId ? getInitialBin() : binOptions[0].value);
        }
    }, [ binOptions ]);

    useEffect(() => {
        if (props.noHeaderOptions) {
            setBin(String(WebStore.viewDeviceId));
        }
    }, [ WebStore.viewDeviceId ]);

    useEffect(() => {
        if (props.structureId) {
            setBin(props.structureId.toString());
        }
    }, [ props.structureId ]);

    const filterData = (data: BinLog[]) => data.filter(log => log.type === 'EVENT');

    const rows = useMemo(() => {
        const data = BinsenseStore.binLogsData.filter(log => log.structId === Number(bin) || log.structId === null);
        return filterData(data);
    }, [ BinsenseStore.binLogsData, bin ]);

    const [ selectedActivityType, setSelectedActivityType ] = useState('');
    const [ selectedActivityTypeError, setSelectedActivityTypeError ] = useState('');
    const [ activityTypeOptions, setActivityTypeOptions ] = useState<SelectOptions[]>([]);
    const [ filteredRows, setFilteredRows ] = useState<BinLog[] >([]);

    useEffect(() => {
        const eventTypes: string[] = [];
        const options: SelectOptions[] = [];
        options.push({
            title: Constants.ALL,
            id: `${ Constants.ALL }_dropdown`,
            value: Constants.ALL,
        });
        let eventType;
        rows.forEach(row => {
            eventType = row.title;
            if (!eventTypes.includes(eventType)) {
                eventTypes.push(eventType);
                options.push({
                    title: eventType,
                    id: `${ replaceSpaces(eventType) }_dropdown`,
                    value: eventType,
                });
            }
        });

        if (options.length > 0) {
            setSelectedActivityType(options[0].title);
        } else {
            setSelectedActivityType('');
        }
        setActivityTypeOptions(options);

    }, [ rows ]);

    useEffect(() => {
        if (selectedActivityType === Constants.ALL) {
            if (includeSensorStatus) {
                setFilteredRows(rows);
            } else {
                const newRows = rows.filter(row => row.title !== 'Sensor status');
                setFilteredRows(newRows);
            }
        } else {
            const newRows = rows.filter(row => row.title === selectedActivityType);
            setFilteredRows(newRows);
        }
    }, [ selectedActivityType, rows, includeSensorStatus ]);

    const columns = [
        {
            field: 'logDate',
            headerName: Constants.RECEIVED_DATE,
        },
        {
            field: 'type',
            headerName: Constants.ACTIVITY_TYPE,
        },
        {
            field: 'log',
            headerName: Constants.DESCRIPTION,
        },
        {
            field: 'accepted',
            headerName: Constants.STATUS,
        },
    ];

    return (
        <Root>
            {!props.noHeaderOptions && (
                <ReportsTableHeader
                    selectField={{
                        value: bin,
                        setValue: setBin,
                        error: binError,
                        setError: setBinError,
                    }}
                    options={binOptions}
                    timeField={{
                        start,
                        setStart,
                        end,
                        setEnd,
                    }}
                    rows={filteredRows}
                    columns={columns}
                    selectedActivityTypeField ={{
                        value: selectedActivityType,
                        setValue: setSelectedActivityType,
                        error: selectedActivityTypeError,
                        setError: setSelectedActivityTypeError,
                    }}
                    activityTypeOptions={activityTypeOptions}
                    includeSensorStatus={includeSensorStatus}
                    setIncludeSensorStatus={setIncludeSensorStatus}
                />
            )}
            {props.displayOnlyTimePicker && (
                <Row>
                    <DateSelectorDiv>
                        <DateRangePicker
                            start={start}
                            setStart={setStart}
                            end={end}
                            setEnd={setEnd}
                            small
                        />
                    </DateSelectorDiv>
                    <ActivityDiv>
                        <SelectFieldInput
                            id="activity_type_select_field"
                            label={Constants.ACTIVITY_TYPE}
                            field={{
                                value: selectedActivityType,
                                setValue: setSelectedActivityType,
                                error: selectedActivityTypeError,
                                setError: setSelectedActivityTypeError,
                            }}
                            options={activityTypeOptions}
                            small
                        />
                    </ActivityDiv>
                    {selectedActivityType === Constants.ALL &&
                        <SensorSwitchDiv>
                            <StyledSwitch
                                id='includeSensorStatus'
                                description='Include Sensor Status'
                                value={includeSensorStatus}
                                setValue={setIncludeSensorStatus}
                            />
                        </SensorSwitchDiv>
                    }
                </Row>
            )}
            <div style={{ width: '100%' }}>
                <ReportsLogsTable
                    data={filteredRows}
                    setData={setFilteredRows}
                />
            </div>
        </Root>
    );
});

export default ReportsLogsPage;
