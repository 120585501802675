/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {useMemo, useState} from 'react';
import {styled, Typography} from '@mui/material';
import {observer} from 'mobx-react-lite';
import BaseModal from '../../common/modal/BaseModal';
import TextFieldInput from '../../common/input/TextFieldInput';
import isStringNotEmpty from '../../../helper/validator/isStringNotEmpty';
import Constants from '../../../helper/Constants';
import SelectFieldInput from '../../common/input/SelectFieldInput';
import BinsenseStore from '../../../stores/BinsenseStore';
import putNote from '../../../controllers/apiCalls/put/putNote';
import {useSnackbar} from 'notistack';
import {Site} from '../../../types/components/ApiTypes';

const Container = styled('div')({
    width: '500px',
    padding: '16px',
});

interface Props {
    open: boolean,
    close: () => void,
    editingId?: number,
    note?: string,
    structureId?: number,
    site: Site,
}

const NewNoteModal = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();

    const binOptions = useMemo(() => {
        const bins = props.site.bins.map(bin => ({
        id: `${ bin.name }_dropdown`,
        title: bin.name,
        value: `${ bin.id }`,
        }));

        const bags = props.site.grainBags.map(bag => ({
        id: `${ bag.name }_dropdown`,
        title: bag.name,
        value: `${ bag.id }`,
        }));

        const buildings = props.site.buildings.map(building => ({
        id: `${ building.name }_dropdown`,
        title: building.name,
        value: `${ building.id }`,
        }));

        return [ ...bins, ...bags, ...buildings ];
    }, [ props.site, BinsenseStore.userData ]);

    const [ note, setNote ] = useState(props.editingId && props.note !== undefined ? props.note : '');
    const [ noteError, setNoteError ] = useState('');
    const [ structure, setStructure ] = useState(props.structureId !== undefined
        ? String(props.structureId) : binOptions[0].value);
    const [ structureError, setStructureError ] = useState('');

    const isValid = () => {
        const newNoteError = isStringNotEmpty(note, 'note.');
        setNoteError(newNoteError);

        return newNoteError === '';
    };

    const submit = () => {
        if (isValid()) {
             if (props.editingId) {
                putNote(enqueueSnackbar, Number(structure), { note,
                    id: props.editingId }, 'Note updated successfully', props.site.id);
             } else {
                putNote(enqueueSnackbar, Number(structure), { note }, 'Note added successfully', props.site.id);
             }
            props.close();
        }
    };

    return (
        <BaseModal
            id="new_note"
            open={props.open}
            close={props.close}
            submit={submit}
            title={Constants.NEW_NOTE}
            disableSubmitOnAwait
        >
            <Container>
                <TextFieldInput
                    id="new_note_input"
                    label={Constants.NOTE}
                    field={{
                        value: note,
                        setValue: setNote,
                        error: noteError,
                        setError: setNoteError,
                    }}
                    textFieldProps={{
                        inputProps: {
                            maxLength: 255,
                        },
                        multiline: true,
                        rows: 5,
                    }}
                />
                <Typography align="right">{note.length}/255</Typography>
                <SelectFieldInput
                    id="structure_input"
                    label={Constants.STRUCTURE}
                    field={{
                        value: structure,
                        setValue: setStructure,
                        error: structureError,
                        setError: setStructureError,
                    }}
                    options={binOptions}
                    textFieldProps={{
                        disabled: props.structureId !== undefined,
                    }}
                />
            </Container>
        </BaseModal>
    );
});

export default NewNoteModal;
