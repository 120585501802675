import { Grid, Typography, styled } from '@mui/material';
import BaseModal from '../../common/modal/BaseModal';
import { observer } from 'mobx-react-lite';
import GridTextFieldInput from '../../common/input/GridTextFieldInput';
import { useEffect, useMemo, useState } from 'react';
import Constants from '../../../helper/Constants';
import { useSnackbar } from 'notistack';
import { DeviceSettingsStructure, Grain, SelectOptions, SiteStructures } from '../../../types/components/ApiTypes';
import getTempUnit from '../../../helper/functions/getTempUnit';
import { cToF, fToC } from '../../../helper/functions/functions';
import WebStore from '../../../stores/WebStore';
import putStructureSettings from '../../../controllers/apiCalls/put/putStructureSettings';
import StyledSwitch from '../../common/input/StyledSwitch';
import BinsenseStore from '../../../stores/BinsenseStore';
import GridSelectFieldInput from '../../common/input/GridSelectFieldInput';

const Container = styled('div')({
    width: '725px',
    minHeight: '600px',
});

const Row = styled('div')({
    width: '700px',
    paddingLeft: '25px',
    paddingRight: '25px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
});

const Column = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'space-between',
    alignSelf: 'start',
    maxWidth: '300px',
    minWidth: '300px',
    paddingTop: '20px',
    paddingBottom: '0px',
});

interface Props {
    open: boolean,
    close: () => void,
    devices: DeviceSettingsStructure[],
    siteId?: number,
}

const EditSelectedBinsModal = observer((props: Props) => {
    const { enqueueSnackbar } = useSnackbar();

    const NO_CHANGE_ID = '-1';
    const NO_CHANGE_TEXT = '- No Change -';
    const CLEAR_LOW_THRESHOLD = 'Clear Low Threshold';

    const [ highThreshold, setHighThreshold ] = useState('');
    const [ highThresholdError, setHighThresholdError ] = useState('');
    const [ clearLowThresholds, setClearLowThresholds ] = useState(false);

    const maxHighThreshold = getTempUnit() === 'F' ? cToF(Constants.MAX_TEMP_HIGH_THRESHOLD_IN_CELSIUS)
        : Constants.MAX_TEMP_HIGH_THRESHOLD_IN_CELSIUS;
    const minHighThreshold = getTempUnit() === 'F' ? cToF(Constants.MIN_TEMP_HIGH_THRESHOLD_IN_CELSIUS)
        : Constants.MIN_TEMP_HIGH_THRESHOLD_IN_CELSIUS;
    const maxLowThreshold = getTempUnit() === 'F' ? cToF(Constants.MAX_TEMP_LOW_THRESHOLD_IN_CELSIUS)
        : Constants.MAX_TEMP_LOW_THRESHOLD_IN_CELSIUS;
    const minLowThreshold = getTempUnit() === 'F' ? cToF(Constants.MIN_TEMP_LOW_THRESHOLD_IN_CELSIUS)
        : Constants.MIN_TEMP_LOW_THRESHOLD_IN_CELSIUS;

    const getMaxLowThresholdForArrow = () => {
        if ( highThreshold !== '' ) {
            return Number(highThreshold) - 1;
        } else {
            return maxLowThreshold;
        }
    };

    const [ maxLowThresholdForArrow, setMaxLowThresholdForArrow ] = useState(getMaxLowThresholdForArrow());

    useEffect(() => {
        setMaxLowThresholdForArrow(getMaxLowThresholdForArrow());
    }, [ highThreshold ]);

    const getDefaultLowThreshold = () => {
        if (clearLowThresholds) {
            return CLEAR_LOW_THRESHOLD;
        } else {
            return '';
        }
    };

    const [ lowThreshold, setLowThreshold ] = useState(getDefaultLowThreshold());
    const [ lowThresholdError, setLowThresholdError ] = useState('');
    const [ weeklyTempRise, setWeeklyTempRise ] = useState('');
    const [ weeklyTempRiseError, setWeeklyTempRiseError ] = useState('');
    const maxThreshold = getTempUnit() === 'F' ? cToF(Constants.MAX_TEMP_HIGH_THRESHOLD_IN_CELSIUS)
        : Constants.MAX_TEMP_HIGH_THRESHOLD_IN_CELSIUS;

    const [ productType, setProductType ] = useState(NO_CHANGE_ID);
    const [ tempAlerts, setTempAlerts ] = useState(NO_CHANGE_ID);
    const [ sendAlertsBelowFreezing, setSendAlertsBelowFreezing ] = useState(NO_CHANGE_ID);
    const [ voltageAlerts, setVoltageAlerts ] = useState(NO_CHANGE_ID);
    const [ autoLevel, setAutoLevel ] = useState(NO_CHANGE_ID);

    const getNoChangeGrain = () => (
        {
            bushelsPerTonne: 0,
            cgc: false,
            id: Number(NO_CHANGE_ID),
            name: NO_CHANGE_TEXT,
            minimumEmc: '0',
            maximumEmc: '0',
        }
    );

    const getOnOffOptions = () => {
        const options: SelectOptions[] = [];
        options.push({
            id: NO_CHANGE_ID,
            value: NO_CHANGE_ID,
            title: NO_CHANGE_TEXT,
        });
        options.push({
            id: '1',
            value: '1',
            title: 'On',
        });
        options.push({
            id: '0',
            value: '0',
            title: 'Off',
        });
        return options;
    };

    const onOffOptions = getOnOffOptions();

    let productList = useMemo(() => BinsenseStore.dataModel.grains, [ BinsenseStore.dataModel.grains ]);
    if (productList && productList.length > 0 ) {
        // To move [Empty] from the last item to the first item of the product option list
        productList = [ productList[productList.length - 1], ...productList.slice(0, -1) ];

        // Add 'No Change' as the first item
        productList = [ getNoChangeGrain(), ...productList ];
    }

    const productOptions = useMemo(() => productList && productList.map((grain: Grain) => ({
        title: grain.name,
        value: grain.id.toString(),
        id: `${ grain.id }_option`,
    })), [ productList ]);

    const isValid = () => {
        let retValue = true;
        if (highThreshold !== '' && Number(highThreshold) > maxThreshold) {
            retValue = false;
            setHighThresholdError(`Maximum value for High Threshold is ${ maxThreshold } ${ getTempUnit() }`);
        }
        if (Number(lowThreshold) > maxLowThreshold) {
            retValue = false;
            setLowThresholdError(`Maximum value for Low Threshold is ${ maxLowThreshold } ${ getTempUnit() }`);
        }
        if ((lowThreshold !== '' && Number(lowThreshold) < minLowThreshold)) {
            retValue = false;
            setLowThresholdError(`Minimum value for Low Threshold is ${ minLowThreshold } ${ getTempUnit() }`);
        }
        if (lowThreshold !== '' && Number(lowThreshold) >= Number(highThreshold)) {
            retValue = false;
            setHighThresholdError('High Threshold must be greater than the Low Threshold');
        }
        if (Number(highThreshold) > maxHighThreshold) {
            retValue = false;
            setHighThresholdError(`Maximum value for High Threshold is ${ maxHighThreshold } ${ getTempUnit() }`);
        }
        if (Number(highThreshold) < minHighThreshold) {
            retValue = false;
            setHighThresholdError(`Minimum value for High Threshold is ${ minHighThreshold } ${ getTempUnit() }`);
        }
        return retValue;
    };

    useEffect(() => {
        setLowThreshold(getDefaultLowThreshold());
    }, [ clearLowThresholds ]);

    const resetErrors = () => {
        if (weeklyTempRise !== '') {
            setWeeklyTempRiseError('');
        }
        if (highThreshold !== '') {
            setHighThresholdError('');
        }
        if (lowThreshold !== '') {
            setLowThresholdError('');
        }
    };

    const editDataMultiple = () => {
        const bins: any[] = [];
        const grainBags: any[] = [];
        const buildings: any[] = [];
        props.devices.forEach(device => {
            if (device.selected) {
                const deviceHardwareType = device.hardwareType.toLowerCase();
                const body = {
                    id: device.id,
                    settings: {
                        productId: productType !== '-1' ? Number(productType) : undefined,
                        name: device.name,
                        highTemperatureThreshold: highThreshold !== '' && deviceHardwareType !== Constants.UNMONITORED.toLowerCase()
                            ? (getTempUnit() === 'F' ? fToC(Number(highThreshold)) : Number(highThreshold))
                            : undefined,
                        lowTemperatureThreshold: lowThreshold !== '' && lowThreshold !== CLEAR_LOW_THRESHOLD &&
                            clearLowThresholds === false && deviceHardwareType !== Constants.UNMONITORED.toLowerCase()
                            ? (getTempUnit() === 'F' ? fToC(Number(lowThreshold))
                            : Number(lowThreshold)) : undefined,
                        weeklyTemperatureRiseThreshold: weeklyTempRise !== '' &&
                            deviceHardwareType !== Constants.UNMONITORED.toLowerCase() &&
                            deviceHardwareType !== Constants.DIRECT.toLowerCase() &&
                            deviceHardwareType !== Constants.SOLO.toLowerCase()
                            ? (getTempUnit() === 'F' ? fToC(Number(weeklyTempRise), true)
                            : Number(weeklyTempRise)) : undefined,
                        autoExposeCoverSensors: (deviceHardwareType === Constants.LIVE.toLowerCase() ||
                            deviceHardwareType === Constants.PLUS.toLowerCase()) && autoLevel !== NO_CHANGE_ID
                            ? autoLevel === '1' ? true : false : undefined,
                        clearLowTemperatureThreshold: clearLowThresholds === true &&
                            deviceHardwareType !== Constants.UNMONITORED.toLowerCase()
                            ? true : undefined,
                        highLowTempAlerts: tempAlerts !== NO_CHANGE_ID &&
                            deviceHardwareType !== Constants.UNMONITORED.toLowerCase() &&
                            deviceHardwareType !== Constants.DIRECT.toLowerCase() &&
                            deviceHardwareType !== Constants.SOLO.toLowerCase()
                            ? tempAlerts === '1' ? true : false : undefined,
                        voltageAlerts: voltageAlerts !== NO_CHANGE_ID && deviceHardwareType !== Constants.DIRECT.toLowerCase() &&
                            deviceHardwareType !== Constants.UNMONITORED.toLowerCase()
                            ? voltageAlerts === '1' ? true : false : undefined,
                        sendAlertsBelowFreezing: sendAlertsBelowFreezing !== NO_CHANGE_ID &&
                            deviceHardwareType !== Constants.UNMONITORED.toLowerCase()
                            ? sendAlertsBelowFreezing === '1' ? true : false : undefined,
                    },
                };
                if (device.typeKey === 'bins') {
                    bins.push(body);
                }
                if (device.typeKey === 'grainBags') {
                    grainBags.push(body);
                }
                if (device.typeKey === 'buildings') {
                    buildings.push(body);
                }
            }
        });
        const siteStructures: SiteStructures = {
            id: props.siteId ? props.siteId : WebStore.selectedSiteId,
            automationHubs: [],
            bins: [],
            buildings: [],
            grainBags: [],
            structNames: [],
            dataGeneratedTime: new Date(),
        };
        if (bins.length > 0) {
            siteStructures.bins = bins;
        }
        if (grainBags.length > 0) {
            siteStructures.grainBags = grainBags;
        }
        if (buildings.length > 0) {
            siteStructures.buildings = buildings;
        }
        putStructureSettings(enqueueSnackbar, props.siteId ? props.siteId : WebStore.selectedSiteId, siteStructures);
    };

    const submit = () => {
        resetErrors();
        if (isValid()) {
            editDataMultiple();
            props.close();
        }
    };

    return (
        <BaseModal
            id="edit_selected_bins_modal"
            open={props.open}
            close={props.close}
            submit={submit}
            title={'Edit Selected Bins'}
            disableSubmitOnAwait
        >
            <Container>
                <Typography
                    sx={{
                        fontSize: '15px',
                        paddingLeft: '16px',
                    }}
                >
                    Fields left blank will not be changed
                </Typography>
                <Row>
                    <Column>
                        <Grid
                            container
                            spacing={2}
                            style={{
                                paddingTop: '16px',
                                paddingBottom: '15px',
                            }}
                        >
                            <GridSelectFieldInput
                                id="edit_commodity_input"
                                label={Constants.COMMODITY}
                                field={{
                                    value: productType,
                                    setValue: setProductType,
                                }}
                                options={productOptions}
                                fullWidth
                            />
                        </Grid>
                        <Grid
                            container
                            spacing={1}
                            style={{ paddingTop: '15px' }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '15px',
                                    paddingLeft: '10px',
                                    paddingBottom: '0px',
                                }}
                            >
                                Temperature Thresholds
                            </Typography>
                            <GridTextFieldInput
                                id="high_threshold_input"
                                label={`High Threshold (${ getTempUnit() })`}
                                field={{
                                    value: highThreshold,
                                    setValue: setHighThreshold,
                                    error: highThresholdError,
                                    setError: setHighThresholdError,
                                }}
                                textFieldProps={{
                                    inputProps: {
                                        type: 'number',
                                    },
                                }}
                                fullWidth
                            />
                            <GridTextFieldInput
                                id="low_threshold_input"
                                label={`Low Threshold (${ getTempUnit() })`}
                                field={{
                                    value: lowThreshold,
                                    setValue: setLowThreshold,
                                    error: lowThresholdError,
                                    setError: setLowThresholdError,
                                }}
                                textFieldProps={{
                                    inputProps: clearLowThresholds ? undefined : ({
                                        type: 'number',
                                        min: minLowThreshold,
                                        max: maxLowThresholdForArrow,
                                    }),
                                    disabled: clearLowThresholds,
                                }}
                                fullWidth
                            />
                            <Grid
                                item
                                spacing={0}
                                style={{
                                    paddingTop: '0px',
                                    paddingBottom: '10px',
                                }}
                            >
                                <StyledSwitch
                                    id='clearLowThresholds'
                                    description='Clear Low Thresholds'
                                    value={clearLowThresholds}
                                    setValue={setClearLowThresholds}
                                />
                            </Grid>
                            <GridSelectFieldInput
                                id="edit_high_low_temp_alerts"
                                label={'High/Low Temperature Alerts'}
                                field={{
                                    value: tempAlerts,
                                    setValue: setTempAlerts,
                                }}
                                options={onOffOptions}
                                fullWidth
                            />
                        </Grid>
                        <Grid
                            container
                            spacing={1}
                            style={{ paddingTop: '40px' }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '15px',
                                    paddingLeft: '10px',
                                    paddingBottom: '0px',
                                }}
                            >
                                Weekly Rise Notifications
                            </Typography>
                            <GridTextFieldInput
                                id="weekly_temperatre_rise_input"
                                label={`Weekly Temp. Rise (${ getTempUnit() })`}
                                field={{
                                    value: weeklyTempRise,
                                    setValue: setWeeklyTempRise,
                                    error: weeklyTempRiseError,
                                    setError: setWeeklyTempRiseError,
                                }}
                                textFieldProps={{
                                    inputProps: {
                                        type: 'number',
                                    },
                                }}
                                fullWidth
                            />
                            <GridSelectFieldInput
                                id="edit_send_alerts_below_freezing"
                                label={'Send Alerts Below Freezing'}
                                field={{
                                    value: sendAlertsBelowFreezing,
                                    setValue: setSendAlertsBelowFreezing,
                                }}
                                options={onOffOptions}
                                fullWidth
                            />
                        </Grid>
                    </Column>
                    <Column>
                        <Grid
                            container
                            spacing={1}
                            style={{ paddingTop: '10px' }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '15px',
                                    paddingLeft: '10px',
                                    paddingBottom: '0px',
                                }}
                            >
                                Other Alert Settings
                            </Typography>
                            <GridSelectFieldInput
                                id="edit_voltage_alerts"
                                label={'Voltage Alerts'}
                                field={{
                                    value: voltageAlerts,
                                    setValue: setVoltageAlerts,
                                }}
                                options={onOffOptions}
                                fullWidth
                            />
                        </Grid>
                        <Grid
                            container
                            spacing={1}
                            style={{ paddingTop: '30px' }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '15px',
                                    paddingLeft: '10px',
                                    paddingBottom: '0px',
                                }}
                            >
                                Auto Level
                            </Typography>
                            <GridSelectFieldInput
                                id="edit_voltage_alerts"
                                label={'Automatically detect grain level'}
                                field={{
                                    value: autoLevel,
                                    setValue: setAutoLevel,
                                }}
                                options={onOffOptions}
                                fullWidth
                            />
                        </Grid>
                    </Column>
                </Row>
            </Container>
        </BaseModal>
    );
});

export default EditSelectedBinsModal;