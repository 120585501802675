/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import DisplayGridLabel from '../../../common/displayTable/DisplayGridLabel';
import DisplayGridContent from '../../../common/displayTable/DisplayGridContent';
import DisplayGridChip from '../../../common/displayTable/DisplayGridChip';
import {observer} from 'mobx-react-lite';
import AccordionTableRowDetails from '../../../common/displayTable/AccordionTableRowDetails';
import {CustomerReportData} from '../../../../types/components/ApiTypes';
import DisplayGridItem from '../../../common/displayTable/DisplayGridItem';
import PowerSymbol from '../../../common/PowerSymbol';
import {Warning} from '@mui/icons-material';
import getTestID from '../../../../helper/functions/getTestID';
import {Typography} from '@mui/material';
import getTimeSince from '../../../../helper/functions/getTimeSince';
import Constants from '../../../../helper/Constants';
import RemoteSerialGridItem from '../../../common/RemoteSerialGridItem';

interface Props {
    row: CustomerReportData,
}

const XS = 1.2;
const fontSize = 12;

const SiteReportTableRowDetails = observer((props: Props) => {
    const renderGridItem = (label: string | number, id: string) => (
        <DisplayGridLabel
            id={id}
            label={label}
            xs={XS}
            fontSize={fontSize}
        />
    );

    const warningLabel = (id: string, label: string | number, showWarning: boolean) => (
        <DisplayGridItem xs={XS}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                {showWarning && (
                    <Warning
                        color="error"
                        style={{ fontSize: 18 }}
                    />
                )}
                <Typography
                    {...getTestID(id)}
                    fontWeight="bold"
                    fontSize={fontSize}
                    paddingLeft="4px"
                >
                    {label}
                </Typography>
            </div>
        </DisplayGridItem>
    );

    const renderVoltageItem = (voltage: number, serial: string, lowBattery: boolean) => voltage !== -1
        ? warningLabel(`${ serial }_voltage`, voltage, lowBattery) : (
            <DisplayGridItem xs={XS}>
                <PowerSymbol
                    acPower
                    batteryLevel={0}
                />
            </DisplayGridItem>
        );

    const showWarning = (date: number, deviceType?: string) => {
        const current = new Date().valueOf();
        if (deviceType === Constants.SOLO) {
            return date ? (current - date) >= (38 * 60 * 60 * 1000) : false;
        } else {
            return date ? current - date >= Constants.MILLISECONDS_IN_A_DAY : false;
        }
    };

    const getTime = (date: number) => getTimeSince(date) === Constants.NA ? '--' : getTimeSince(date);

    return (
        <AccordionTableRowDetails>
            {props.row.devices.map(device => (
                <DisplayGridContent key={`${ device.serial }_row`}>
                    {renderGridItem(device.deviceType, `structure_${ device.serial }`)}
                    {device.deviceType === Constants.REMOTE ? (
                        <RemoteSerialGridItem
                            device={device}
                            xs={XS}
                            fontSize={fontSize}
                            testId={`${ props.row.structure }_serial_${ device.serial }`}
                        />
                    ) : renderGridItem(device.serial, `${ props.row.structure }_serial_${ device.serial }`)}
                    {renderGridItem(getTime(device.firstAssociated), `${ props.row.structure }_first_associated_${ device.serial }`)}
                    {renderVoltageItem(device.voltage, device.serial, device.lowBattery)}
                    {renderGridItem(getTime(device.lastVoltage), `${ props.row.structure }_last_voltage_call_in_${ device.serial }`)}
                    {warningLabel(`${ props.row.structure }_last_cable_call_in_${ device.serial }`,
                        getTime(device.lastCable), showWarning(device.lastCable, device.deviceType))}
                    {renderGridItem('--', `temp_cables_${ device.serial }`)}
                    {renderGridItem('--', `moist_cables_${ device.serial }`)}
                    {renderGridItem(device.device, `${ props.row.structure }_device_${ device.serial }`)}
                    <DisplayGridChip
                        id={`${ props.row.structure }_status_${ device.serial }`}
                        status={device.status}
                        xs={1.2}
                        size="small"
                    />
                </DisplayGridContent>
            ))}
        </AccordionTableRowDetails>
    );
});

export default SiteReportTableRowDetails;
