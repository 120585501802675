/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {styled} from '@mui/material';
import Constants from '../../../../../helper/Constants';
import StyledDataGridPro from '../../../../common/StyledDataGridPro';
import {Cable} from '../../../../../types/components/ApiTypes';
import {useMemo} from 'react';
import {GridCellParams} from '@mui/x-data-grid-pro';
import SensorTableCell from './SensorTableCell';
import getBinViewTableRows from '../../../../../helper/functions/getBinViewTableRows';
import WebStore from '../../../../../stores/WebStore';

interface Props {
    cables: Cable[],
    highTemperatureThreshold: number,
    lowTemperatureThreshold: number,
}

const Container = styled('div')({
    width: '100%',
    height: '500px',
});

const DeviceSensorTable = (props: Props) => {
    const rows = useMemo(() => getBinViewTableRows(props.cables, props.highTemperatureThreshold,
        props.lowTemperatureThreshold), [ props.cables, props.highTemperatureThreshold,
            props.lowTemperatureThreshold ]);

    const columns = [
        {
            field: 'row',
            headerName: Constants.ROW,
        },
        {
            field: 'avgTemp',
            headerName: 'Avg. Temp',
            renderCell: (params: GridCellParams) => (
                <SensorTableCell
                        value={params.value}
                        isHigh={params.row.tempThresholdHigh}
                        sensorColor={params.row.sensorColor === Constants.SENSOR_COLOR_YELLOW
                            ? WebStore.lightMode ? Constants.SENSOR_COLOR_CHARCOAL : Constants.SENSOR_COLOR_DARK_YELLOW
                            : params.row.sensorColor}
                />
                ),
        },
        {
            field: 'avgMoisture',
            headerName: 'Avg. Moisture',
            renderCell: (params: GridCellParams) => (
                <SensorTableCell
                    value={params.value}
                    isHigh={params.row.moistureThresholdHigh}
                />
            ),
        },
    ];

    return (
        <Container>
            <StyledDataGridPro
                rows={rows}
                columns={columns}
            />
        </Container>
    );
};

export default DeviceSensorTable;
