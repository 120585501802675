/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

// @ts-ignore
import 'react-tabulator/lib/styles.css';
import 'react-tabulator/css/bootstrap/tabulator_bootstrap.min.css';
import {useEffect, useMemo, useRef, useState} from 'react';
import BinsenseStore from '../../../stores/BinsenseStore';
import getTestID from '../../../helper/functions/getTestID';
import {Download} from '@mui/icons-material';
import Constants from '../../../helper/Constants';
import {Button, MenuItem, Typography} from '@mui/material';
import {styled} from '@mui/material';
import {observer} from 'mobx-react-lite';
import exportTableToCSV from '../../../helper/functions/exportTableToCSV';
import getCommunicationTableRowData from '../../../helper/functions/getCommunicationTableRowData';
import getSiteCommunicationData from '../../../controllers/apiCalls/get/getSiteCommunicationData';
import {useSnackbar} from 'notistack';
import DashboardSkeleton from '../../common/DashboardSkeleton';
import presetCommunicationSummaryData from '../../../helper/temp/presetCommunicationSummaryData';
import InfoTooltip from '../../common/InfoTooltip';
import TooltipStore from '../../../stores/TooltipStore';
import TooltipMessages from '../../../helper/TooltipMessages';
import CommunicationSummaryTable from './CommunicationSummaryTable';
import PopupMenu from '../../common/PopupMenu';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import useWindowSize from '../../../helper/hooks/useWindowSize';
import SiteReportTable from './siteReport/SiteReportTable';
import {Site} from '../../../types/components/ApiTypes';
import StyledSwitch from '../../common/input/StyledSwitch';

const TitleRow = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '20px',
    paddingRight: '20px',
});

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

const Root = styled('div')({
    width: '100%',
});

interface Props {
    site: Site,
}

const CommunicationSummaryPage = observer((props: Props) => {
    const windowSize = useWindowSize();
    const windowHeight = useMemo(() => windowSize[1], [ windowSize ]);

    const {enqueueSnackbar} = useSnackbar();
    const ref = useRef<any>(null);

    const [ exportPDFClicked, setExportPDFClicked ] = useState(false);
    const [ anchorEl, setAnchorEl ] = useState(null);
    const open = Boolean(anchorEl);

    const [ isCable, setIsCable ] = useState(true);

    const data = useMemo(() => BinsenseStore.communicationTableData.cableCommunication, [ BinsenseStore.communicationTableData ]);

    useEffect(() => {
        BinsenseStore.setCommunicationTableData(presetCommunicationSummaryData);
        if (props.site.id > 0) {
            getSiteCommunicationData(enqueueSnackbar, props.site.id);
        }
    }, [ props.site ]);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getDateColumns = () => {
        const dates = [];
        if (data) {
            for (let i = 0; i < data.columns.length; i++) {
                dates.push({
                    field: `column_${ i }_value`,
                    headerName: data.columns[i].header,
                });
            }
        }
        return dates;
    };

    const columns = useMemo(() => [ {
        field: 'bin',
        headerName: 'Bin',
        width: 200,
    }, ...getDateColumns(),
    ], [ data ]);

    const handlePdfDownload = async () => {
        const element = ref.current;
        const canvas = await html2canvas(element);
        const dataURL = canvas.toDataURL('image/png');

        const pdf = new jsPDF();
        const imgProperties = pdf.getImageProperties(dataURL);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
        pdf.addImage(dataURL, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('communicationTable.pdf');
    };

    const handlePdfClick = () => {
        setExportPDFClicked(true);
        setTimeout(() => {
            handlePdfDownload();
            setExportPDFClicked(false);
        }, 1000);
    };

    return !BinsenseStore.awaitingUserData && !BinsenseStore.awaitingApi ? (
        <Root>
            <TitleRow>
                <Row>
                    <Typography color="primary">
                        {Constants.COMMUNICATION_SUMMARY_CONNECTED_CABLES}
                    </Typography>
                    <InfoTooltip
                        id="communication_summary_tooltip_button"
                        open={TooltipStore.beginTooltips && TooltipStore.showCommunicationSummaryMessages}
                        message={TooltipMessages.COMMUNICATION_SUMMARY}
                        onClick={() => TooltipStore.setShowCommunicationSummaryMessages(false, true)}
                    />
                </Row>
                {data.rows.length > 0 && (
                    <Row>
                        <div>
                            <StyledSwitch
                                id="communication_summary_toggle"
                                description={isCable ? 'Cables' : 'Status'}
                                value={isCable}
                                setValue={setIsCable}
                            />
                            <Button
                                {...getTestID('communication_summary_export_button')}
                                onClick={handleClick}
                                variant='contained'
                                startIcon={<Download/>}
                                disabled={data.rows.length === 0}
                            >
                                {Constants.EXPORT}
                            </Button>
                            <PopupMenu
                                anchorEl={anchorEl}
                                open={open}
                                handleClose={handleClose}
                            >
                                <MenuItem
                                    {...getTestID('export_to_csv_button')}
                                    onClick={() => exportTableToCSV(columns, getCommunicationTableRowData(data.rows),
                                        'communication_summary')}
                                >
                                    {Constants.EXPORT_TO_CSV}
                                </MenuItem>
                                <MenuItem
                                    {...getTestID('export_to_pdf_button')}
                                    onClick={handlePdfClick}
                                >
                                    {Constants.EXPORT_TO_PDF}
                                </MenuItem>
                            </PopupMenu>
                        </div>
                    </Row>
                )}
            </TitleRow>
            {exportPDFClicked && (
                <DashboardSkeleton height={windowHeight}/>
            )}
            {data.rows.length > 0 ? (
                <div
                    ref={ref}
                    style={{ paddingTop: exportPDFClicked ? '20px' : undefined }}
                >
                    {exportPDFClicked && (
                        <>
                            <Typography
                                color='primary'
                                padding='20px'
                            >
                                {Constants.SITE_REPORT}
                            </Typography>
                            <SiteReportTable
                                site={props.site}
                                displayAll
                            />
                            <Typography
                                color='primary'
                                padding='20px'
                            >
                                {Constants.COMMUNICATION_SUMMARY}
                            </Typography>
                        </>
                    )}
                    <CommunicationSummaryTable isCable={isCable}/>
                </div>
            ) : (
                <div>{Constants.NO_DATA_TO_DISPLAY}</div>
            )}
        </Root>
    ) : (
        <div
            style={{
                paddingLeft: '20px',
                paddingRight: '20px',
            }}
        >
            <DashboardSkeleton height={750}/>
        </div>
    );
});

export default CommunicationSummaryPage;
