/* eslint no-use-before-define: 0 */

import { useMemo, useState } from 'react';
import { Bin, Building, Cable, GrainBag, Sensor } from '../../../../types/components/ApiTypes';
import getTempUnit from '../../../../helper/functions/getTempUnit';
import BinsenseStore from '../../../../stores/BinsenseStore';
import Constants from '../../../../helper/Constants';
import { cToF } from '../../../../helper/functions/functions';
import SvgZoomPanComponent from '../../../common/svg/SvgZoomPanComponent';
import FanGif from '../../../common/FanGif';
import getSensorColor from '../../../../helper/functions/getSensorColor';

interface Props {
    id: string;
    structure: Bin | GrainBag | Building,
    setBinSensorId?:(id: string) => void,
    setBinSensor?: (sensor?: Sensor) => void,
    displayInTooltip?: boolean,
    adjustSize?: number,
    imageScale?: number,
    displayAmbientTemp?: boolean,
}

export class FanStyleSheet {
    static getSheet(imageScale:number) {
        return {
            fanImage: {
                height: 50 * imageScale,
                width: 50 * imageScale,
                top: 400 * imageScale,
                left: 120 * imageScale,
                alignSelf: 'center',
            },
            fanImageHopper: {
                height: 40 * imageScale,
                width: 40 * imageScale,
                top: 335 * imageScale,
                left: 100 * imageScale,
                alignSelf: 'center',
            },
        };
    }
};

const BinImageSVG = (props: Props) => {
    const [ sensorIsSelected, setSensorIsSelected ] = useState(false);
    const tempUnit = useMemo(getTempUnit, [ BinsenseStore.userData.settings.preferences, BinsenseStore.dealerUserData.settings.preferences ]);
    const fans = props.structure.hardware.fans;
    const grainLevel = props.structure.status.percentFull;
    const structCables: Cable[] = JSON.parse(JSON.stringify(props.structure.hardware.cables));
    const binType = 'binType' in props.structure && props.structure.binType ? props.structure.binType : '';
    const imageScale = props.imageScale ? props.imageScale : 1;
    const adaptSize = (value: number) => {
        return props.displayInTooltip ? value / (props.adjustSize ? props.adjustSize : 2) : value;
    }

    const svgWidth = adaptSize(350);
    const svgHeight = adaptSize(500);
    const fanWidth = (fans.length > 0 && !props.displayInTooltip) ? 25 : 0;
    const hopperOffset = binType.toLowerCase() === 'hopper' ? adaptSize(40) : 0;
    const binHeight = (adaptSize(320) - fanWidth - hopperOffset * 2);
    const binWidth = adaptSize(300) - fanWidth - hopperOffset;
    const binTop = adaptSize(150);
    const binBottom = binHeight + binTop;
    const binLeft = adaptSize(10) + hopperOffset / 2;
    const binMiddle = (binWidth / 2) + binLeft;
    const structRatio = binWidth / 10;
    const roofCapPosition = (binHeight / 5) + (fanWidth / 2) + (hopperOffset / 2);
    const defaultMaxCables = 15;
    const cableCount = structCables.length;
    const cableWidth = adaptSize(structCables.length > defaultMaxCables ? 2 : 4);
    const sensorRadius = adaptSize(structCables.length > defaultMaxCables ? 3 : 7);
    const baseOffset = adaptSize(5);
    const ribCount = binHeight / adaptSize(20);
    const capWidth = baseOffset * 2; 
    const grainOffset = adaptSize(2);
    const tooltipFontSize = 12;
    const tooltipBoxWidth = 75;
    const tooltipBoxHeight = 20;
    const defaultRibHeight = 7;
    const ribHeight = props.adjustSize ? defaultRibHeight - props.adjustSize : defaultRibHeight;

    const greyColor1 = '#767889';
    const greyColor2 = '#7E8393';
    const greyColor3 = '#9999A2';
    const greyColor4 = '#686874';
    const roofColor = '#565757';
    const tempCableColor = '#07713C';
    const moistureCableColor = '#003D61';
    const tooltipBoxColor = '#FFFFFF';
    const grainColor = '#00B700';
    const smartIdColor1 = '#073E61';
    const smartIdColor2 = '#EFC419';
    const blackColor = '#000000';

    let grainheight = 0;
    if(grainLevel){
        grainheight = binHeight * (grainLevel / 100);
    }

    const [ showTempValue, setShowTempValue ] = useState(false);
    const [ sensorId, setSensorId ] = useState('-1');
    const [ sensorColor, setSensorColor ] = useState('');
    const [ temperature, setTemperature ] = useState(0);
    const [ moistureContent, setMoistureContent ] = useState(0);
    const [ rh, setRh ] = useState(0);
    const [ isMoistureCable, setIsMoistureCable ] = useState(false);
    const [ sensorTooltipX, setSensorTooltipX ] = useState(0);
    const [ sensorTooltipY, setSensorTooltipY ] = useState(0);
    const [ isLeft, setIsLeft ] = useState(false);
    const [ cableId, setCableId ] = useState(0);
    const [ smartId, setSmartId ] = useState(0);
    const [ smartIdTooltipX, setSmartIdTooltipX ] = useState(0);
    const [ smartIdTooltipY, setSmartIdTooltipY ] = useState(0);
    const [ showSmartId, setShowSmartId ] = useState(false);
    const [ highestCoveredPoint, setHighestCoveredPoint ] = useState({x:0,y:0});

    // points used for plotting the grain level
    let points: {x: number, y: number, allCovered?: boolean}[] = [];

    const highTemperatureThreshold = props.structure.settings.highTemperatureThreshold;
    const lowTemperatureThreshold = props.structure.settings.lowTemperatureThreshold;

    const renderSensorTooltip = () => {
        if(showTempValue) {
            const svgContents : any[] = [];
            let tempValue = 'No Reading';
            let boxHeight = tooltipBoxHeight;
            let boxWidth = tooltipBoxWidth;
            let rounded = 0;
            if(temperature !== null) {
                const temp = tempUnit === 'F' ? cToF(temperature) : temperature;
                rounded = Math.round(temp * 10) / 10;
                tempValue = String(rounded.toFixed(1)).concat('°').concat(tempUnit)
                boxWidth = tooltipBoxWidth - 20;
            }
            let moistureValue = '';
            if(isMoistureCable) {
                if(moistureContent) {
                    rounded = Math.round(moistureContent * 10) / 10;
                    moistureValue = String(rounded.toFixed(1)).concat('%');
                    boxHeight = tooltipBoxHeight + 10;
                } else if(rh){
                    rounded = Math.round(rh * 10) / 10;
                    moistureValue = String(rounded.toFixed(1)).concat('%rh');
                    boxHeight = tooltipBoxHeight + 10;
                }
            }
            
            let arrowBaseX = isLeft ? sensorTooltipX + 11 :  sensorTooltipX + boxWidth + 9;
            let arrowBaseY = sensorTooltipY - 2;
            let xFactor = isLeft ? -1 : 1;
            let yFactor = 4;
            const arrowPoints = String(arrowBaseX).concat(',').concat(String(arrowBaseY))
                .concat(' ').concat(String(arrowBaseX + (6 * xFactor))).concat(',').concat(String(arrowBaseY + yFactor))
                .concat(' ').concat(String(arrowBaseX)).concat(',').concat(String(arrowBaseY + yFactor * 2));
            {/* tooltip arrow */}            
            svgContents.push(<polygon key={String(sensorId).concat('arrow')} points={arrowPoints} fill={tooltipBoxColor} />);
            {/* tooltip box */}
            svgContents.push(<rect key={String(sensorId).concat('box')} id={String(sensorId).concat('box')} rx='3' x={sensorTooltipX + 10} y={sensorTooltipY - 5} 
                width={boxWidth} height={boxHeight} fill={tooltipBoxColor}/>);
            {/* Temperature text */}
            svgContents.push(<text key={String(sensorId).concat('temp')} id={String(sensorId).concat('temp')} x={sensorTooltipX + 15} y={sensorTooltipY + 8} 
                fill={sensorColor} fontSize={tooltipFontSize} fontWeight={'bold'}>{tempValue}</text>);
            if(moistureValue !== '')
            {
                {/* Moisture text */}
                svgContents.push(<text key={String(sensorId).concat('rh')} id={String(sensorId).concat('rh')} x={sensorTooltipX + 15} y={sensorTooltipY + 20} 
                fill={sensorColor} fontSize={tooltipFontSize} fontWeight={'bold'}>{moistureValue}</text>);
            }
            return svgContents;
        } else {
            return <view/>;
        }
    }

    const sensorClick = (newSensor: Sensor, sensorX: number, sensorY: number, sensorColor: string, 
            isMoistureCable: boolean, isLeft: boolean, cable: Cable ) => {
        if(newSensor.idString !== sensorId || !sensorIsSelected) {
            setSensorIsSelected(true);
            setShowTempValue(true);
            setSensorId(newSensor.idString!);
            setSensorColor(sensorColor === Constants.SENSOR_COLOR_YELLOW ? Constants.SENSOR_COLOR_CHARCOAL : sensorColor);
            setTemperature(newSensor.temperature);
            setRh(newSensor.rh);
            setMoistureContent(newSensor.moistureContent);
            setIsMoistureCable(isMoistureCable);
            setSensorTooltipX(sensorX);
            setSensorTooltipY(sensorY);
            setIsLeft(isLeft);
            if(props.setBinSensorId){
                props.setBinSensorId(newSensor.idString!);
            }
            if (props.setBinSensor) {
                props.setBinSensor(newSensor);
            }
        } 
        else {
            setSensorIsSelected(false);
            setShowTempValue(!showTempValue);
            setSensorId('-1');
            if(props.setBinSensorId){
                props.setBinSensorId('-1');
            }
            if (props.setBinSensor) {
                props.setBinSensor(undefined);
            }
        }
        
        const cusEvent = new CustomEvent('onSensorSelected',
        {
            detail: {
                sensorId: newSensor.idString!,
                cableId: cable.smartId,
                sensorExposed: newSensor.sensorMuted,
                sensorDisabled: newSensor.disabled,
                sensorPosition: newSensor.index + 1,
            }
        });
        window.dispatchEvent(cusEvent);
    };

    const sensorHoverEnter = (newSensor: Sensor, sensorX: number, sensorY: number, sensorColor: string, 
        isMoistureCable: boolean, isLeft: boolean ) => {
        if(newSensor.idString !== sensorId && !sensorIsSelected) {
            setShowTempValue(true);
            setSensorId(newSensor.idString!);
            setSensorColor(sensorColor === Constants.SENSOR_COLOR_YELLOW ? Constants.SENSOR_COLOR_CHARCOAL : sensorColor);
            setTemperature(newSensor.temperature);
            setRh(newSensor.rh);
            setMoistureContent(newSensor.moistureContent);
            setIsMoistureCable(isMoistureCable);
            setSensorTooltipX(sensorX);
            setSensorTooltipY(sensorY);
            setIsLeft(isLeft);
            if(props.setBinSensorId){
                props.setBinSensorId(newSensor.idString!);
            }
            if (props.setBinSensor) {
                props.setBinSensor(newSensor);
            }
        }
    };

    const sensorHoverExit = () => {
        if (!sensorIsSelected) {
            setShowTempValue(false);
            setSensorId('-1');
            if(props.setBinSensorId){
                props.setBinSensorId('-1');
            }
            if (props.setBinSensor) {
                props.setBinSensor(undefined);
            }
        }
    }

    const renderRibs = () => {
        const svgContents : any[] = [];
        const scaleFactor = binHeight/ ribCount;
        let y = binTop + scaleFactor;
        let i=0
        while( y < binBottom) {
            svgContents.push(<rect key={'rib'.concat(String(i))} rx='3' x={binLeft - baseOffset} y={y} width={binWidth + baseOffset * 2} height={ribHeight} fill={roofColor}/>);
            y = binTop + scaleFactor + ( i++ * scaleFactor);
        }
        return svgContents;
    };

    const renderMainStructure = () => {
        const svgContents : any[] = [];
        svgContents.push(<rect key='mainStructure1' x={binLeft} y={binTop} width={binWidth} height={binHeight} fill={greyColor1}/>);
        svgContents.push(<rect key='mainStructure2' x={binLeft + structRatio} y={binTop} width={structRatio * 6} height={binHeight} fill={greyColor2}/>);
        svgContents.push(<rect key='mainStructure3' x={binLeft + (structRatio * 3)} y={binTop} width={structRatio * 2} height={binHeight} fill={greyColor3}/>);
        svgContents.push(<rect key='mainStructure4' x={binLeft + (structRatio * 9)} y={binTop} width={structRatio} height={binHeight} fill={greyColor4}/>);
        return svgContents;
    };

    const renderRoofandBase = () => {
        const svgContents : any[] = [];
        const roofPoints = String(binLeft).concat(',').concat(String(binTop))
            .concat(' ').concat(String(binMiddle - capWidth)).concat(',').concat(String(roofCapPosition))
            .concat(' ').concat(String(binMiddle + capWidth)).concat(',').concat(String(roofCapPosition))
            .concat(' ').concat(String(binWidth + binLeft)).concat(',').concat(String(binTop));

        {/* roof cap */}
        svgContents.push(<rect key='roofCap' rx={adaptSize(3).toString()} x={(binMiddle - (capWidth))} y={roofCapPosition - 2} width={capWidth * 2} height={adaptSize(2).toString()} fill={greyColor4}/>);
        {/* roof */}
        svgContents.push(<polygon key='roof' points={roofPoints} fill={roofColor} />);
        {/* roof rib */}
        svgContents.push(<rect key='roofRibTop' rx={adaptSize(3).toString()} x={binLeft - baseOffset} y={binTop - 1} width={binWidth + baseOffset * 2} height={adaptSize(7).toString()} fill={roofColor}/>);
        if(binType.toLowerCase() === 'hopper') {
            const standWidth = adaptSize(10);
            const standHeight = roofCapPosition * 1.5;
            {/* Stands */}
            svgContents.push(<rect key='hopperStand1' x={binLeft} y={binBottom} width={standWidth} height={standHeight} fill={greyColor1}/>);
            svgContents.push(<rect key='hopperStand2' x={binLeft + (binWidth * 0.25)} y={binBottom} width={standWidth} height={standHeight - standWidth} fill={greyColor3}/>);
            svgContents.push(<rect key='hopperStand3' x={binLeft + (binWidth * 0.75)} y={binBottom} width={standWidth} height={standHeight - standWidth} fill={greyColor3}/>);
            svgContents.push(<rect key='hopperStand4' x={binLeft + binWidth - standWidth} y={binBottom} width={standWidth} height={standHeight} fill={greyColor1}/>);
            {/* hopper base */}
            const hopperPoints = String(binLeft).concat(',').concat(String(binBottom))
                .concat(' ').concat(String(binMiddle - capWidth)).concat(',').concat(String(binBottom + roofCapPosition))
                .concat(' ').concat(String(binMiddle + capWidth)).concat(',').concat(String(binBottom + roofCapPosition))
                .concat(' ').concat(String(binWidth + binLeft)).concat(',').concat(String(binBottom));
            svgContents.push(<polygon key='hopperBase' points={hopperPoints} fill={roofColor} />);
        } else{
            {/* base */}
            svgContents.push(<rect key='base' rx={adaptSize(5).toString()} x={binLeft - baseOffset} y={binHeight + binTop} width={binWidth + (baseOffset * 2)} height={baseOffset * 2} fill={roofColor}/>);
        }
        return svgContents;
    };

    const renderCables = () => {
        const svgContents : any[] = [];
        const cableLenght = binBottom - binTop;
        let cableX = binMiddle;
        let cableY = 0;
        let prevCableX = cableX;
        let sensors : any[] = [];
        let smartIds : any[] = [];
        let cableColor = '';
        let isMoistureCable = false;
        let hopperCableOffset = 0;
        
        if(cableCount > 0) {
            let isLeft = true;
            structCables.sort((a,b) => b.sensorCount - a.sensorCount);
            if (structCables[0].type === 'moisture') {
                isMoistureCable = true;
                cableColor = moistureCableColor;
            } else {
                isMoistureCable = false;
                cableColor = tempCableColor;
            }
            const sensorScaleFactor = cableLenght / structCables[0].sensors.length;
            
            if(binType.toLowerCase() === 'hopper') {
                hopperCableOffset = roofCapPosition - grainOffset;
            }

            svgContents.push(<line key={String(structCables[0].id)} id={String(structCables[0].id)} x1={cableX} y1={roofCapPosition + 5} x2={cableX} y2={binBottom + hopperCableOffset} stroke={cableColor} strokeWidth={cableWidth}/>);
            
            sensors = renderSensors(structCables[0].sensors, sensorScaleFactor, cableX, false, isMoistureCable, structCables[0]);
            svgContents.push(...sensors);
            
            if (!props.displayInTooltip) {
                if(cableCount > defaultMaxCables ) {
                    svgContents.push(<ellipse key={String(structCables[0].id).concat('dot')} cx={cableX} cy={roofCapPosition - 15} rx={sensorRadius} ry={sensorRadius} fill={smartIdColor1}
                    onClick={() => smartIdClick(structCables[0].id, structCables[0].smartId, binMiddle, roofCapPosition - 10)} />);
                } else {
                    smartIds = renderSmartIds(structCables[0].smartId, cableX, roofCapPosition + 5);
                    svgContents.push(...smartIds);
                }
            }
            if(cableCount > 1) {
                const maxCablePerSide = Math.ceil((cableCount - 1) / 2);
                let scaleFactor = ((binWidth / 2)) / (maxCablePerSide + 1);
                if (scaleFactor > binWidth / 4)
                {
                    scaleFactor = binWidth / 4; 
                }
                
                // determine roof angle : 1.5708 radians is 90 degrees
                const h = binTop - roofCapPosition;
                const w = (binWidth - capWidth) / 2;
                const hyp = Math.sqrt(w * w + h * h);
                const angle = Math.asin(w / hyp);
                const yPos = Math.tan(1.5708 - angle)
                let structCable : Cable;
                
                for(let i=1; i<cableCount; i++){
                    structCable = structCables[i];
                    if (structCable.type === 'moisture') {
                        isMoistureCable = true;
                        cableColor = moistureCableColor;
                    } else {
                        isMoistureCable = false;
                        cableColor = tempCableColor;
                    }
                    
                    if(isLeft){
                        cableX = prevCableX - (scaleFactor * i);
                        cableY = (yPos * (binMiddle-cableX)) + roofCapPosition;
                    } else {
                        cableX = prevCableX + (scaleFactor * i);
                        cableY = (yPos * (cableX - binMiddle)) + roofCapPosition;
                    }
                    
                    if(hopperOffset > 0) {
                        if(fanWidth > 0){
                            hopperCableOffset = cableY - binTop;
                        } else {
                            hopperCableOffset = cableY - binTop + capWidth;
                        }
                    }
                    
                    svgContents.push(<line key={String(i)} id={String(i)} x1={cableX} y1={cableY} x2={cableX} y2={binBottom - hopperCableOffset} stroke={cableColor} strokeWidth={cableWidth}/>);
                    
                    sensors = renderSensors(structCable.sensors, sensorScaleFactor, cableX, isLeft, isMoistureCable, structCable);
                    svgContents.push(...sensors);
                    
                    if (!props.displayInTooltip) {
                        if(cableCount > defaultMaxCables ) {
                            const smartIdX = cableX;
                            const smartIdY = cableY;
                            const newSmartId = structCable.smartId;
                            const id = structCable.id;
                            svgContents.push(<ellipse key={String(structCables[0].id).concat('dot')} cx={cableX} cy={cableY - 20} rx={sensorRadius} ry={sensorRadius} fill={smartIdColor1}
                                onClick={() => smartIdClick(id, newSmartId, smartIdX, smartIdY - 15)} />);
                        } else {
                            smartIds = renderSmartIds(structCable.smartId, cableX, cableY);
                            svgContents.push(...smartIds);
                        }
                    }
                    
                    prevCableX = cableX;
                    isLeft = !isLeft;
                }
            }
        }
        return svgContents;
    };

    const renderSmartIds = (smartId: number, cableX: number, cableY: number) => {
        const svgContents : any[] = [];
        svgContents.push(<ellipse key={String(smartId).concat('smartIdCirlce2')} cx={cableX} cy={cableY - 30} rx='18' ry='18' fill={smartIdColor2}/>);
        svgContents.push(<ellipse key={String(smartId).concat('smartIdCirlce1')} cx={cableX} cy={cableY - 30} rx='15' ry='15' fill={smartIdColor1}/>);
		
        let textX = cableX - 5;
        if(smartId > 9 && smartId <= 99) {
            textX = cableX - 7;
        }
        else if (smartId > 99) {
            textX = cableX - 13;
        }
		svgContents.push(<text key={String(smartId).concat('smartIdText')} x={textX} y={cableY - 25} fill={smartIdColor2} fontSize={14} fontWeight={'bold'}>{smartId}</text>);
        return svgContents;
    };

    const smartIdClick = (newCableId: number, newSmartId: number, smartIdX: number, smartIdY: number) => {
        if(newCableId !== cableId) {
            setShowSmartId(true);
            setCableId(newCableId);
            setSmartId(newSmartId);
            setSmartIdTooltipX(smartIdX);
            setSmartIdTooltipY(smartIdY);
        } else {
            setShowSmartId(!showSmartId);
        }
    };

    const renderSmartIdTooltip = () => {
        if(showSmartId) {
            return renderSmartIds(smartId, smartIdTooltipX, smartIdTooltipY);
        } else {
            return <view/>;
        }
    }

    const renderSensors = (structSensors: Sensor[], sensorScaleFactor: number, cableX: number, isLeft: boolean, isMoistureCable: boolean, cable: Cable) => {
        const svgContents : any[] = [];
        const sensorCount = structSensors.length;
        const sensorBuffer = adaptSize(10);
        let y = binBottom + sensorBuffer;
        let toolTipX = cableX;        
        structSensors.sort((a,b) => a.index - b.index);
        let  lastCoveredSensorPoint = undefined;

        let allCovered = false;
        for(let i=0; i<sensorCount; i++){
            y = y - sensorScaleFactor;
            const sensorColor = getSensorColor(structSensors[i].temperature, structSensors[i].sensorMuted,
                highTemperatureThreshold, lowTemperatureThreshold);
            const toolTipY = y;
            if(!isLeft) {
                if(structSensors[i].temperature) {
                    toolTipX = cableX - tooltipBoxWidth;
                } else {
                    toolTipX = cableX - tooltipBoxWidth - 20; // For No Reading
                }
            }

            if(sensorColor === Constants.SENSOR_COLOR_RED || sensorColor === Constants.SENSOR_COLOR_YELLOW) {
                const gradientRadius = sensorRadius * 3;
                const gradientId = sensorColor === Constants.SENSOR_COLOR_RED ? String(structSensors[i].id).concat('GradientRed') : String(structSensors[i].id).concat('GradientYellow');
                const gradientFillId = String('url(#').concat(gradientId).concat(')');
                svgContents.push(<radialGradient key={gradientId} id={gradientId} >
                    <stop offset='10%' stop-color={sensorColor} stopOpacity={1} />
                    <stop offset='30%' stop-color={sensorColor} stopOpacity={0.7} />
                    <stop offset='50%' stop-color={sensorColor} stopOpacity={0.5}/>
                    <stop offset='70%' stop-color={sensorColor} stopOpacity={0.3}/>
                    <stop offset='100%' stop-color={'transparent'} stopOpacity={0} />
                </radialGradient>);
                svgContents.push(<ellipse key={gradientId.concat('Ellipse')} cx={cableX} cy={y} rx={gradientRadius} ry={gradientRadius} fill={gradientFillId}/>);
            }
            if (!structSensors[i].sensorMuted){
                if (sensorCount - 1 === i) {
                    allCovered = true;
                }

                lastCoveredSensorPoint = {x: cableX, y: y - sensorRadius, allCovered: allCovered};
            }
                
            
            
            svgContents.push(<ellipse key={String(structSensors[i].id).concat('opac')} cx={cableX} cy={y} rx={sensorRadius * 2} ry={sensorRadius * 2} opacity={0}/>);
        
            svgContents.push(<ellipse key={String(structSensors[i].id)} cx={cableX} cy={y} rx={sensorRadius} ry={sensorRadius} fill={sensorColor} 
            cursor={'pointer'}
            onClick={() => {!props.displayInTooltip && sensorClick(structSensors[i], toolTipX, toolTipY, sensorColor, isMoistureCable, isLeft, cable)}}
            onMouseEnter={() => sensorHoverEnter(structSensors[i], toolTipX, toolTipY, sensorColor, isMoistureCable, isLeft)}
            onMouseLeave={() => sensorHoverExit()} />);
            
            if (structSensors[i].disabled) {
                svgContents.push(<line key={String(structSensors[i].id).concat('_disabled')} id={String(structSensors[i].id).concat('_disabled')} x1={cableX - sensorRadius - 1} y1={y + sensorRadius + 1} x2={cableX + sensorRadius + 1} y2={y - sensorRadius - 1} stroke={blackColor} strokeWidth={cableWidth}/>);
            }
        }
        

        if (lastCoveredSensorPoint) {
            if (lastCoveredSensorPoint.y < highestCoveredPoint.y || highestCoveredPoint.y == 0) {
                setHighestCoveredPoint({ x: lastCoveredSensorPoint.x, y: lastCoveredSensorPoint.y });
            }
            points.push(lastCoveredSensorPoint);
        } else {
            lastCoveredSensorPoint = {x: cableX, y: binBottom }
            points.push(lastCoveredSensorPoint)
        }
        
        return svgContents;
    }

    const renderGrain = () => {
        const svgContents: any[] = [];
        // sort the points left to right
        points = points.sort((a, b) => a.x - b.x);
        for (let i = 0; i < points.length; i++) {
            // if all the sensors are covered,
            // fill grain to highest covered sensor in bin
            if (points[i].allCovered === true) {
                points[i].y = highestCoveredPoint.y;
            }
        }

        if (points.length > 0) {
            // add point to left of first point
            let leftPoint = { x: binLeft, y: points[0].y };

            // add bottom left point
            let bottomLeftPoint = { x: binLeft, y: binBottom };

            if (binType.toLowerCase() === 'hopper') {
                // add bottom right point
                let bottomRightPoint = { x: binWidth + binLeft, y: binBottom };

                // add point to right of last point
                let rightPoint = { x: binWidth + binLeft, y: points[points.length - 1].y }
                points.push(rightPoint);
                points.push(bottomRightPoint);
            } else {
                // add bottom right point
                let bottomRightPoint = { x: binWidth + (baseOffset * 2), y: binBottom };

                // add point to right of last point
                let rightPoint = { x: binWidth + (baseOffset * 2), y: points[points.length - 1].y }
                points.push(rightPoint);
                points.push(bottomRightPoint);
            }


            points.push(bottomLeftPoint);
            points.push(leftPoint);


            points = points.sort((a, b) => a.x - b.x);
        }

        // gradient grain color
        svgContents.push(<linearGradient key='grain-gradient' id='grain-gradient' x1={0} y1={0} x2={1} y2={0}>
            <stop offset='0' stopColor={Constants.SENSOR_COLOR_GREEN} stopOpacity='1' />
            <stop offset='0.25' stopColor={grainColor} stopOpacity='1' />
            <stop offset='0.75' stopColor={grainColor} stopOpacity='1' />
            <stop offset='1' stopColor={Constants.SENSOR_COLOR_GREEN} stopOpacity='1' />
        </linearGradient>);

        
        let path = '';
        let prevPoint = {x:0,y:0};
        if (points.length > 0)
        {
            prevPoint = points[0];
            path = `M${points[0].x} ${points[0].y}`;
        }
        for (let i = 1; i < points.length; i++) {
            // for bezier curve
            let curvePoint1 = {x: (prevPoint.x + points[i].x) / 2 , y: prevPoint.y};
            let curvePoint2 = {x: (prevPoint.x + points[i].x) / 2 , y: points[i].y};
            path = path.concat(`C${curvePoint1.x},${curvePoint1.y} ${curvePoint2.x},${curvePoint2.y} ${points[i].x},${points[i].y}`);
            prevPoint = {x: points[i].x, y: points[i].y}
        }
        svgContents.push(
            <path d={`${path}`} stroke={grainColor} strokeWidth={0} fill={'url(#grain-gradient)'} />
        )

        // fill bottom of hopper
        if (binType.toLowerCase() === 'hopper' && grainheight > 0) {
            const hopperGrain = String(binLeft + grainOffset).concat(',').concat(String(binBottom - grainOffset))
                .concat(' ').concat(String(binMiddle - capWidth)).concat(',').concat(String(binBottom + roofCapPosition - grainOffset))
                .concat(' ').concat(String(binMiddle + capWidth)).concat(',').concat(String(binBottom + roofCapPosition - grainOffset))
                .concat(' ').concat(String(binWidth + binLeft - grainOffset)).concat(',').concat(String(binBottom - grainOffset));
            {/* hopper base */ }
            svgContents.push(<polygon key='hopperGrain' points={hopperGrain} fill='url(#grain-gradient)' />);
        }

        return svgContents;
    };

    const renderFan = () => {
        const svgContents : any[] = [];
        if(fans.length > 0) {
            let isRunning = false;
            const fanCount = fans.length;
            fans.forEach(fan => {
                if(fan.status.status === 'RUNNING' || fan.status.status === 'MANUAL_ON'){
                    isRunning = true;
                }
            });

            svgContents.push(<FanGif isHopper={hopperOffset > 0} isRunning={isRunning} imageScale={imageScale} binWidth={binWidth + 10} binBottom={binBottom - 50} />)
            
            if(fanCount > 1) {
                svgContents.push(<text key='fanCount' x={hopperOffset > 0 ? binWidth + 50 : binWidth + 30} y={binBottom - 55} fill={greyColor1} fontSize={tooltipFontSize} >{fanCount}</text>);
            }
        }

        return svgContents;
    };

    const cableSvgContent = renderCables();

    return (
        <SvgZoomPanComponent
            displayAmbientTemp={props.displayAmbientTemp}
            structId={props.structure.id}
            >
            <div
                style={{
                    display: 'flex',
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <svg
                    id={String(props.structure.id)}
                    width={Math.ceil(svgWidth * imageScale)}
                    height={Math.ceil(svgHeight * imageScale)}
                    viewBox={`${baseOffset * -1} 0 ${svgWidth} ${svgHeight}`}
                    x={0}
                    y={0}
                >
                    {renderRibs()}
                    {renderMainStructure()}
                    {renderRoofandBase()}
                    {renderGrain()}
                    {cableSvgContent}
                    {renderSensorTooltip()}
                    {!props.displayInTooltip && renderSmartIdTooltip()}
                    {!props.displayInTooltip && renderFan()}
                </svg>
            </div>
        </SvgZoomPanComponent>

    );
};

export default BinImageSVG;