/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Paper, Skeleton, styled} from '@mui/material';
import Constants from '../../../../../helper/Constants';
import {useEffect, useMemo, useState, useCallback} from 'react';
import moment from 'moment';
import ViewDeviceTempChart from './ViewDeviceTempChart';
import ViewDeviceFanChart from './ViewDeviceFanChart';
import ViewDeviceMoistureChart from './ViewDeviceMoistureChart';
import {observer} from 'mobx-react-lite';
import getBinGraphData from '../../../../../controllers/apiCalls/get/getBinGraphData';
import {useSnackbar} from 'notistack';
import BinsenseStore from '../../../../../stores/BinsenseStore';
import WebStore from '../../../../../stores/WebStore';
import ChartTimeRangePicker from './ChartTimeRangePicker';
import DashboardSkeleton from '../../../../common/DashboardSkeleton';
import ViewDeviceGrainChart from './ViewDeviceGrainChart';
import ViewDeviceAlertChart from './ViewDeviceAlertChart';
import ViewDeviceVoltageChart from './ViewDeviceVoltageChart';
import getSensorBinGraphData from '../../../../../controllers/apiCalls/get/getSensorBinGraphData';
import ChartMLTagButton from './ChartMLTagButton';
import MLTagDetailModal from '../../../../dealer/contentPanels/MLTagDetailModal';
import { BinGraphData } from '../../../../../types/components/ApiTypes';

interface Props {
    binChartVisible: any,
}

const SkeletonRoot = styled('div')({
    width: '100%',
    padding: '20px',
    paddingTop: '10px',
});

const DeviceChartPaper = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();
    const data = useMemo(() => BinsenseStore.binGraphData, [ BinsenseStore.binGraphData ]);
    const [ reset, setReset ] = useState(true);

    const [ timeUnit, setTimeUnit ] = useState(Constants.ONE_WEEK);
    let currentTime = new Date().getTime();
    const [ start, setStart ] = useState<any>(moment(currentTime - Constants.MILLISECONDS_IN_A_WEEK));
    const [ end, setEnd ] = useState<any>(moment(currentTime));
    const [ includeAlerts, setIncludeAlerts ] = useState(false);
    const [ includeLevel, setIncludeLevel ] = useState(false);
    const [ includeVoltage, setIncludeVoltage ] = useState(false);
    const [ callRefresh, setCallRefresh ] = useState(false);

    let previousSensorId = '0';
    let countClicksOnSameSensorId = 0;

    const [ binSensorId, setBinSensorId ] = useState('-1');
    const [ smartCableId, setSmartCableId ] = useState(0);
    const [ sensorPosition, setSensorPosition ] = useState(0);

    const [ tagModalOpen, setTagModalOpen ] = useState(false);

    const handleSensorSelect = useCallback((event) => {
        const sensorId = event.detail.sensorId;
        previousSensorId = WebStore.previousSensorId;
        countClicksOnSameSensorId = WebStore.countClicksOnSameSensorId;
        setSmartCableId(event.detail.cableId);
        setSensorPosition(event.detail.sensorPosition);

        if (sensorId === previousSensorId) {
            countClicksOnSameSensorId++;
        } else {
            countClicksOnSameSensorId = 0;
        }
        if (sensorId && countClicksOnSameSensorId % 2 === 0) {
            setBinSensorId(sensorId);
            previousSensorId = sensorId;
        } else {
            setBinSensorId('-1');
        }
        WebStore.setCountClicksOnSameSensorId(countClicksOnSameSensorId);
        WebStore.setPreviousSensorId(previousSensorId);
    }, []);

    useEffect(() => {

        window.addEventListener('onSensorSelected', handleSensorSelect);
        return () => {
            window.removeEventListener('onSensorSelected', handleSensorSelect);
        };

    }, [ handleSensorSelect ]);

    useEffect(() => {
        currentTime = new Date().getTime();
        if (timeUnit === Constants.ONE_DAY) {
            setEnd(moment(currentTime));
            setStart(moment(currentTime - Constants.MILLISECONDS_IN_A_DAY));
        }
        else if (timeUnit === Constants.ONE_WEEK) {
            setEnd(moment(currentTime));
            setStart(moment(currentTime - Constants.MILLISECONDS_IN_A_WEEK));
        }
        else if (timeUnit === Constants.ONE_MONTH) {
            setEnd(moment(currentTime));
            setStart(moment(currentTime - Constants.MILLISECONDS_IN_A_MONTH));
        }
    },[ timeUnit ]);

    useEffect(() => {
        // Zoom event will change start and end date.
        // To avoid duplicate API calls, callRefresh check is added.
        if (!callRefresh) {
            if (binSensorId >= '0' && WebStore.viewDeviceId >= 0) {
                getSensorBinGraphData(enqueueSnackbar, WebStore.viewDeviceId, binSensorId, new Date(start).getTime(),
                    new Date(end).getTime() + Constants.MILLISECONDS_IN_A_DAY);
            } else if (WebStore.viewDeviceId >= 0) {
                getBinGraphData(enqueueSnackbar, WebStore.viewDeviceId, new Date(start).getTime(),
                    new Date(end).getTime() + Constants.MILLISECONDS_IN_A_DAY);
            }
            setCallRefresh(true);
            setTimeout(() => setCallRefresh(false), 2000);
        }
    }, [ WebStore.viewDeviceId, binSensorId, start, end ]);

useEffect(() => {
    setReset(true);
}, [ data ]);

useEffect(() => {} , [ tagModalOpen ]);
useEffect(() => {
    setBinSensorId('-1');
}, [ WebStore.viewDeviceId, props.binChartVisible ]);

    const getValidBinGraphData = (binGraphData: BinGraphData[]) => {
        const validData: BinGraphData[] = [];
        for (let i = 0; i < binGraphData.length; i++) {
            const row = binGraphData[i];
            if ((Number(binSensorId) >= 0 && row.temperature !== null) || (binSensorId == '-1' && row.maxtmp !== null)) {
                validData.push(row);
            }
        }
        return validData;
    };

    const tempChart = useMemo(() => (
        <ViewDeviceTempChart
        data={getValidBinGraphData(data)}
        id="tempchart"
        legend_show={true}
        timeUnit={timeUnit}
        setStart={setStart}
        setEnd={setEnd}
        setTimeUnit={setTimeUnit}
        sensorId = {binSensorId}
        />
        ) ,[ data ]);

    const voltageChart = useMemo(() =>(
        <ViewDeviceVoltageChart
        data={data}
        id="voltagechart"
        legend_show={false}
        height="80%"
        setTimeUnit={setTimeUnit}
        setStart={setStart}
        setEnd={setEnd}
        />
        ) , [ data ]);

    const moistureChart = useMemo(() =>(
        <ViewDeviceMoistureChart
            data={getValidBinGraphData(data)}
            id="moisturechart"
            legend_show={true}
            height="100%"
            setTimeUnit={setTimeUnit}
            setStart={setStart}
            setEnd={setEnd}
            sensorId = {binSensorId}
        />
    ), [ data ]);

    const alertChart = useMemo(() => (
        <ViewDeviceAlertChart
            data={getValidBinGraphData(data)}
            id="alertchart"
            legend_show={true}
            height="70%"
            setTimeUnit={setTimeUnit}
            setStart={setStart}
            setEnd={setEnd}
        />
    ), [ data ]);

    const grainLevelChart = useMemo(() => (
        <ViewDeviceGrainChart
            data={getValidBinGraphData(data)}
            id="grainchart"
            legend_show={false}
            height="80%"
            setTimeUnit={setTimeUnit}
            setStart={setStart}
            setEnd={setEnd}
        />
    ), [ data ]);

    const fanChartLevel = useMemo (() => (
        <ViewDeviceFanChart
            data={data}
            id="fanchart"
            legend_show={true}
            height="45%"
            setTimeUnit={setTimeUnit}
            setStart={setStart}
            setEnd={setEnd}
        />
        ), [ data ]);

    const chart = useMemo(() => {
        if (reset) {
            setTimeout(() => setReset(false), 0);
        }
        return !reset ? (
            <div id="wrapper">
                {BinsenseStore.dealerUserData != null &&
                 BinsenseStore.dealerUserData.dealerGroups.length > 0 &&
                 BinsenseStore.dealerUserData.dealerGroups[0].name === 'Intragrain' &&
                 <ChartMLTagButton
                    data={data}
                    label='Tag this data for ML learning'
                    start={start}
                    setStart={setStart}
                    end={end}
                    setEnd={setEnd}
                    sensorId = {binSensorId}
                    structId = {WebStore.viewDeviceId}
                    showModal = {tagModalOpen}
                    setShowModal = {setTagModalOpen}
                 />}
                {binSensorId !== '-1' && <p>Cable {smartCableId} Sensor {sensorPosition}</p>}
                <div id="chartTemp">
                    {tempChart}
                </div>
                <div id="chartMoisture">
                    {moistureChart}
                </div>
                <div id="chartFan">
                    {fanChartLevel}
                </div>
                {includeLevel && (
                <div id="chartGrain">
                    {grainLevelChart}
                </div>
                )}
                {includeAlerts && (
                <div id="chartAlert">
                    {alertChart}
                </div>
                )}
                {includeVoltage && (
                <div id="chartVoltage">
                    {voltageChart}
                </div>
                )}
            </div>
        ) : (
            <Skeleton
                variant="rectangular"
                width="100%"
                height={500}
            />
        );
    }, [ reset, data, includeVoltage, includeLevel, includeAlerts ]);

    return !BinsenseStore.awaitingUserData ? (
        <div
            style={{
                width: '100%',
                height: '100%',
                paddingRight: '40px',
            }}
        >
            <Paper style={{ width: '100%' }}>
                {chart}
                {tagModalOpen && (
                    <MLTagDetailModal
                        open
                        close={() => setTagModalOpen(false)}
                        structureId={WebStore.viewDeviceId}
                        startTime={start}
                        endTime={end}
                        sensorId={binSensorId}
                    />
                )}
                <ChartTimeRangePicker
                    timeUnit={timeUnit}
                    setTimeUnit={setTimeUnit}
                    start={start}
                    setStart={setStart}
                    end={end}
                    setEnd={setEnd}
                    includeAlerts={includeAlerts}
                    setIncludeAlerts={setIncludeAlerts}
                    includeLevel={includeLevel}
                    setIncludeLevel={setIncludeLevel}
                    includeVoltage={includeVoltage}
                    setIncludeVoltage={setIncludeVoltage}
                />
            </Paper>
        </div>
    ) : (
        <SkeletonRoot>
            <DashboardSkeleton height={400}/>
        </SkeletonRoot>
    );
});

export default DeviceChartPaper;
